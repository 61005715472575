import React from 'react'
import Layout from '../components/layout'
import PanierReset from '../components/panier-reset'
import Seo from '../components/SEO'

import '../ui/confirmation.css'

const post = {
    title: "Merci pour votre commande ! Hâte d'avoir votre avis !",
    description: 'Vous recevrez sous peu reçu par email et vos produits entre 3 à 5 jours ouvrables',
    image: 'https://archimouss.ch/assets/images/logo/big.png'
  }

const Confirmation = () => {
    return (
        <>
            <Seo title={post.title} description={post.description} image={post.image} index={true}/>
            <Layout displayIcons={true}>
                <div className='main'>
                    <div className='main-content'>
                        <div className='main-confirmation'>
                            <div className='content-confirmation'>
                                <PanierReset /> 
                                <h1>Merci pour votre commande !</h1>
                                <p>Vous recevrez sous peu, par email, un reçu.</p>
                                <p>Les délais de livraisons sont estimés de 3 à 5 jours ouvrables</p>
                                <p>À bientôt !</p>
                                <p>Bruno de Arti'Mouss</p>
                            </div>
                            
                        </div>
                       
                    </div>
                </div>
            </Layout>
        </>
  )
}

export default Confirmation