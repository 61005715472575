import React, {useEffect} from 'react'
import { useShoppingCart } from 'use-shopping-cart'

const PanierReset = () => {

    const {clearCart} = useShoppingCart()

    useEffect(() => {
        clearCart()
     });

    return (
        <>
        </>
    )
}

export default PanierReset